import styled from 'styled-components'
import { Flex } from 'packages/uikit'
import { AbstradexStyledActionButton } from '../Button/styles'

export const StyledFaucet = styled.div`
  position: absolute;
  z-index: 9;
  top: calc(100% + 8px);
  left: 0;
  right: 0;

  width: calc(100vw - 22px);

  padding: 16px 32px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.absModalBg};

  ${({ theme }) => theme.mediaQueries.md} {
    left: 12px;
    width: calc(100% - 22px);
  }
`

export const FaucetButton = styled(AbstradexStyledActionButton)`
  min-height: 29px !important;
  max-height: 31px;
  font-weight: 400;
  min-width: 125px;

  &.pancake-button--disabled {
    background-color: ${({ theme }) => theme.colors.absMainColor};
  }
`

export const StyledFaucetItem = styled(Flex)`
  width: 100%;
  align-items: center;
  padding: 8px 0;
  justify-content: space-between;
`

export const StyledIcon = styled(Flex)`
  width: 25px;
  aspect-ratio: 1/1;
  height: 100%;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.absMainColor};

  justify-content: center;
  align-items: center;
`

import verifySigAbi from 'config/abi/verifySig.json'
import { VerifySig } from 'config/abi/types/VerifySig'
import { VERIFY_ADDRESS } from 'config/constants'
import { useContract } from 'hooks/useContract'
import { CHAIN_ID } from 'config/constants/networks'

export const useVerifyContract = (contractAddress: string) => {
  return useContract<VerifySig>(contractAddress, verifySigAbi)
}

export const getMessageHash = async (): Promise<string> => {
  const verifyContract = useVerifyContract(VERIFY_ADDRESS[CHAIN_ID])
  const messageHash = await verifyContract.getMessageHash(1, 1)
  return messageHash
}

import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount } from '@abstra-dex/sdk'
import { NODES } from 'config'
import { ERC20_ABI } from 'config/abi/erc20'
import { CHAIN_ID_BRIDGE } from 'config/constants/networks'
import { ethers } from 'ethers'
import { getTokenBridge } from 'state/bridge/types'

export const getEtherBalance = (
  address: string,
  chainId: ChainId,
): Promise<{
  currency: CurrencyAmount
  chainId: ChainId
}> => {
  const provider = new ethers.providers.JsonRpcProvider(NODES[chainId])
  return provider
    .getBalance(address)
    .then((balance) => {
      return {
        currency: CurrencyAmount.ether(balance.toBigInt()),
        chainId,
      }
    })
    .catch(() => {
      return {
        currency: CurrencyAmount.ether(JSBI.BigInt(0)),
        chainId,
      }
    })
}

export const getErc20Balance = (address: string, currency: Token) => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(NODES[CHAIN_ID_BRIDGE])
    const contract = new ethers.Contract(currency.address, ERC20_ABI, provider)
    return contract
      .balanceOf(address)
      .then((balance) => {
        return {
          currency: new TokenAmount(currency, balance.toString()),
          chainId: currency.chainId,
        }
      })
      .catch(() => {
        return {
          currency: new TokenAmount(currency, JSBI.BigInt(0)),
          chainId: currency.chainId,
        }
      })
  } catch {
    return {
      currency: new TokenAmount(currency, JSBI.BigInt(0)),
      chainId: currency.chainId,
    }
  }
}

export const getBalances = async (chainId: ChainId, address = '') => {
  const foreignCoins = getTokenBridge()

  const balances: { currency: TokenAmount; chainId: ChainId }[] = await Promise.all(
    foreignCoins.map(async (token: Token) => getErc20Balance(address, token)).filter((token) => token),
  )

  const chains = [ChainId.MAINNET]
  const nativeBalances = await Promise.all(chains.map((chain) => getEtherBalance(address, chain)))
  return [...nativeBalances, ...balances]
}

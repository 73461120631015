/* eslint-disable @typescript-eslint/no-empty-function */
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ChainId, CurrencyAmount, TokenAmount } from '@abstra-dex/sdk'
import useDebounce from 'hooks/useDebounce'
import { getBalances } from './helpers'

export type BlastContextProps = {
  balances: {
    currency: CurrencyAmount | TokenAmount
    chainId: ChainId
  }[]
  refetchBalances: () => void
}

export const BlastContext = createContext<BlastContextProps>({
  balances: [],
  refetchBalances: () => {},
})

const BlastContextProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const { chainId, account } = useActiveWeb3React()

  const [balances, setBalances] = useState<
    {
      currency: CurrencyAmount | TokenAmount
      chainId: ChainId
    }[]
  >([])

  const refLoading = useRef({
    balance: false,
  })
  const isConnected = useDebounce(!!account, 1000)
  const chainidDebounce = useDebounce(chainId, 1000)

  const fetchBalances = useCallback(async () => {
    if (refLoading.current.balance) return
    refLoading.current.balance = true

    try {
      if (isConnected) {
        const results = await getBalances(chainidDebounce, account)
        if (results) setBalances(results.filter((tokenAmount) => tokenAmount))
      } else {
        setBalances([])
      }
    } catch (e) {
      console.error(e)
    } finally {
      refLoading.current.balance = false
    }
  }, [account, chainidDebounce, isConnected])

  useEffect(() => {
    fetchBalances()
  }, [fetchBalances, isConnected])

  const value = useMemo(
    () => ({
      balances,
      refetchBalances: fetchBalances,
    }),
    [balances, fetchBalances],
  )
  return <BlastContext.Provider value={value}>{children}</BlastContext.Provider>
}
export default BlastContextProvider

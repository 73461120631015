/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, memo } from 'react'
import styled from 'styled-components'
import { useMatchBreakpoints } from '../../hooks'
import { Flex } from '../Box'
import isTouchDevice from '../../util/isTouchDevice'
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import MenuItem from '../MenuItem/MenuItem'
import { MenuItemsProps } from './types'

const MenuItems: React.FC<MenuItemsProps> = ({ items = [], activeItem, activeSubItem, ...props }) => {
  const { isMobile } = useMatchBreakpoints()

  return (
    <Flex {...props} alignItems="center" justifyContent="flex-start" width="100%">
      {items.map(({ label, items: menuItems = [], href, icon }) => {
        const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color
        const isActive = activeItem === href
        const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? {} : { href }
        const Icon = icon
        return (
          <DropdownMenu key={`${label}#${href}`} items={menuItems} py={1} activeItem={activeSubItem}>
            <ActiveStar active={isActive}>
              {isActive && isMobile ? (
                <ActiveIcon ml="8px">
                  <img src="/images/abstradex/svgs/link-active.svg" alt="" />
                </ActiveIcon>
              ) : null}
              <MenuItem {...linkProps} isActive={isActive} statusColor={statusColor}>
                {icon && createElement(Icon as any, { width: '26px', height: '26px', color: 'none' })}
                {label || (icon && createElement(Icon as any, { width: '26px', color: 'none' }))}
              </MenuItem>
            </ActiveStar>
          </DropdownMenu>
        )
      })}
    </Flex>
  )
}

export default memo(MenuItems)

const ActiveStar = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;

  position: relative;
  border-radius: 8px;
  width: 100%;

  &:before {
    content: '';
    opacity: 0.61;
    border-radius: inherit;

    background: ${({ theme, active }) => (active ? `${theme.colors.absMainColor}` : 'transparent')};

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

const ActiveIcon = styled(Flex)`
  filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(90deg) brightness(100%) contrast(100%);
  position: absolute;
`

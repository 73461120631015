import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg fill="absWhiteColor" viewBox="0 0 48 45" {...props}>
      <path d="M19.8043 30.3031H28.1549V45H19.8043V30.3031ZM0.5 15.2391H14.7091L4.60782 7.73324L10.1972 3.25422L19.827 10.97V0H28.1773V10.97L37.807 3.27179L43.392 7.73324L33.2954 15.2216H47.5V21.4151H33.2143L43.3788 29.1133L37.807 33.4876L24.0021 22.6749L10.1972 33.4876L4.60782 29.1311L14.7763 21.4327H0.5V15.2391Z" />
    </Svg>
  )
}

export default Icon

import React, { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { useMatchBreakpoints } from '../../../../hooks'
import { Box, Flex } from '../../../../components/Box'
import { ChevronDownIcon } from '../../../../components/Svg'
import { UserMenuProps, variants } from './types'
// import MenuIcon from './MenuIcon'
import { UserMenuItem } from './styles'

export const StyledUserMenu = styled(Flex)`
  position: relative;
  cursor: pointer;

  min-height: 29px;
  border: none;
  border-radius: 10px;
  padding: 0 16px;

  justify-content: center;
  align-items: center;
  grid-gap: 8px;

  font-weight: 700;

  color: ${({ theme }) => theme.colors.absWhiteColor};
  background: ${({ theme }) => theme.colors.absBlackColor};

  ${({ theme }) => theme.mediaQueries.md} {
    background-color: ${({ theme }) => theme.colors.absMainColor};
    color: ${({ theme }) => theme.colors.absBlackColor};
    min-height: 44px;
  }

  &:hover {
    opacity: 0.65;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -25px;
    width: 100%;
    height: 25px;
    background: transparent;
  }
`

export const LabelText = styled.div`
  font-size: 10px;
  border-radius: 25px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.absWhiteColor};

  ${({ theme }) => theme.mediaQueries.md} {
    margin-left: 8px;
    margin-right: 4px;
    color: none;
    font-size: 16px;
  }

  .mobile {
    color: ${({ theme }) => theme.colors.absWhiteColor};
    background: ${({ theme }) => theme.colors.absBlackColor};
  }
`

const Menu = styled.div<{ isOpen: boolean }>`
  background-color: #222426;
  border-radius: 16px;
  pointer-events: auto;
  width: 280px;
  visibility: visible;
  z-index: 1001;
  padding: 4px 16px;

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}
  ${UserMenuItem}:first-child {
    border-radius: 8px 8px 0 0;

    &:hover {
      background: transparent;
      opacity: 0.65;
    }
  }

  ${UserMenuItem}:last-child {
    border-radius: 0 0 8px 8px;

    &:hover {
      background: transparent;
      opacity: 0.65;
    }
  }
`

const UserMenu: React.FC<UserMenuProps> = ({
  account,
  text,
  avatarSrc,
  variant = variants.DEFAULT,
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null)
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null)
  const accountEllipsis = account ? `${account.substring(0, 5)}...${account.substring(account.length - 5)}` : null
  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: 'absolute',
    placement: 'bottom',
    modifiers: [{ name: 'offset', options: { offset: [-35, 20] } }],
  })
  const mobileAccountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null
  const { isMobile } = useMatchBreakpoints()

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true)
    }

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node
      if (target && !tooltipRef?.contains(target)) {
        setIsOpen(false)
        evt.stopPropagation()
      }
    }

    targetRef?.addEventListener('mouseenter', showDropdownMenu)
    targetRef?.addEventListener('mouseleave', hideDropdownMenu)

    return () => {
      targetRef?.removeEventListener('mouseenter', showDropdownMenu)
      targetRef?.removeEventListener('mouseleave', hideDropdownMenu)
    }
  }, [targetRef, tooltipRef, setIsOpen])

  return (
    <Flex alignItems="center" height="100%" ref={setTargetRef} {...props}>
      <StyledUserMenu
        onTouchStart={() => {
          setIsOpen((s) => !s)
        }}
      >
        {/* <MenuIcon avatarSrc={avatarSrc} variant={variant} /> */}
        {isMobile ? (
          <LabelText className="mobile" title={text || account}>
            {text || mobileAccountEllipsis}
          </LabelText>
        ) : (
          <>
            <Flex flexShrink="0" ml="16px">
              <img src="/images/abstradex/svgs/account-wallet.svg" alt="" />
            </Flex>
            <LabelText title={text || account}>{text || accountEllipsis}</LabelText>
            <ChevronDownIcon color="absWhiteColor" width="24px" mr="16px" />
          </>
        )}
      </StyledUserMenu>
      <Menu style={styles.popper} ref={setTooltipRef} {...attributes.popper} isOpen={isOpen}>
        <Box onClick={() => setIsOpen(false)}>{children?.({ isOpen })}</Box>
      </Menu>
    </Flex>
  )
}

export default UserMenu

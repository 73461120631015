import styled from 'styled-components'

export const ComingSoon = () => {
  return (
    <StyledComingSoon>
      <img src="/images/abstradex/coming-soon.png" alt="" />
    </StyledComingSoon>
  )
}

const StyledComingSoon = styled.div`
  margin: auto;
  max-width: 440px;
  width: 100%;
  height: 50vh;
  aspect-ratio: 8/5;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

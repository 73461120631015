/* eslint-disable import/no-useless-path-segments */
import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="46" height="40" viewBox="0 0 46 40" {...props}>
      <path d="M18.0496 26.0974L17.2887 36.8C18.3773 36.8 18.8488 36.3324 19.4143 35.7708L24.5183 30.8929L35.0945 38.6381C37.0342 39.7191 38.4008 39.1499 38.924 36.8537L45.8662 4.32404L45.8681 4.32212C46.4833 1.45478 44.8312 0.333535 42.9413 1.03695L2.13551 16.6597C-0.649409 17.7407 -0.607243 19.2932 1.66209 19.9966L12.0945 23.2415L36.3269 8.07879C37.4673 7.32362 38.5043 7.74145 37.6513 8.49662L18.0496 26.0974Z" />
    </Svg>
  )
}

export default Icon

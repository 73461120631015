/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import { baseColors, darkColors, lightColors } from '../../theme/colors'
// import { Flex, Box } from '../Box'
import styled from 'styled-components'
// import { Link } from '../Link'
// import {
//   StyledFooter,
//   StyledIconMobileContainer,
//   StyledList,
//   StyledListItem,
//   StyledText,
//   StyledSocialLinks,
//   StyledToolsContainer,
// } from './styles'
// import { FooterProps } from './types'
// import { ThemeSwitcher } from '../ThemeSwitcher'
// import LangSelector from '../LangSelector/LangSelector'
// import CakePrice from '../CakePrice/CakePrice'
// import { LogoWithTextIcon, ArrowForwardIcon } from '../Svg'
// import { Button } from '../Button'
// import { Colors } from '../..'
import SocialLinks from './Components/SocialLinks'
import { FooterProps } from './types'
import { Link } from '../Link'
// import { footerLinks } from './config'

const AbstradexMainFooter = styled.footer`
  display: flex;
  background-color: ${({ theme }) => theme.colors.absTopBottomColor};
  min-height: ${({ theme }) => theme.abstradexValues.footerHeight}px;
  padding: 36px 10px;
  border-top: 0.5px solid ${({ theme }) => theme.colors.absInputNumbericColor};
  margin-top: 5rem;
`

const FooterContainer = styled.div`
  width: 100%;
  /* max-width: ${({ theme }) => theme.abstradexValues.contentWidth}px; */
  margin: auto;
  padding: 0 12px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 0 36px;
  }

  &.footer-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 36px;

    ${({ theme }) => theme.mediaQueries.md} {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
`

const SectionLeft = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 36px;
  column-gap: 56px;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  }

  .footer-left__powered {
    display: flex;
    align-items: center;
    grid-gap: 8px;

    img {
      width: 53px;
    }

    .powerd-info-top {
      font-size: 28px;
      line-height: 36.96px;
      font-weight: 800;
      color: ${({ theme }) => theme.colors.absWhiteColor};
    }

    .powerd-info-bottom {
      font-size: 14px;
      font-weight: 700;
      color: #7f7f7f;
    }
  }
`

const SectionRight = styled.div`
  .footer-right__list {
    display: flex;
    align-items: center;
    grid-gap: 24px;
    justify-content: center;

    list-style: none;
    color: ${({ theme }) => theme.colors.absWhiteColor};
  }
`

// eslint-disable-next-line no-empty-pattern
const MenuItem: React.FC<FooterProps> = ({}) => {
  return (
    <>
      <AbstradexMainFooter>
        <FooterContainer className="footer-container">
          <SectionLeft>
            <div className="footer-left__powered">
              <img src="/images/abstradex/svgs/logo.svg" alt="footer-logo" />

              <div className="powerd-info">
                <div className="powerd-info-top">AbstraDEX</div>
                <div className="powerd-info-bottom">© 2023 Powered By AbstraDEX</div>
              </div>
            </div>
          </SectionLeft>

          <SocialLinks />

          <SectionRight>
            <ul className="footer-right__list">
              <li>
                <Link href="https://docs.abstradex.xyz/" external style={{ marginBottom: '0' }}>
                  Docs
                </Link>
              </li>
              <li>
                <Link
                  href="https://mirror.xyz/0x803327585B8315062E21F455463c5DA38065fd1E"
                  external
                  style={{ marginBottom: '0' }}
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link href="mailto:contact@abstradex.xyz" external style={{ marginBottom: '0' }}>
                  Contact us
                </Link>
              </li>
            </ul>
          </SectionRight>
        </FooterContainer>
      </AbstradexMainFooter>
    </>
    // <FooterCopyLink>
    //   <p>©2023 All Rights Reserved - AbstraDEX</p>
    // </FooterCopyLink>
    // <StyledFooter p={['40px 16px', null, '56px 40px 32px 40px']} {...props} justifyContent="center">
    //   <Flex flexDirection="column" width={['100%', null, '1200px;']}>
    //     <StyledIconMobileContainer display={['none', null, 'none']}>
    //       <LogoWithTextIcon isDark width="130px" />
    //     </StyledIconMobileContainer>
    //     <Flex
    //       order={[2, null, 1]}
    //       flexDirection={['column', null, 'row']}
    //       justifyContent="space-between"
    //       alignItems="flex-start"
    //       mb={['42px', null, '36px']}
    //     >
    //       {items?.map((item) => (
    //         <StyledList key={item.label}>
    //           <StyledListItem>{item.label}</StyledListItem>
    //           {item.items?.map(({ label, href, isHighlighted = false }) => (
    //             <StyledListItem key={label}>
    //               {href ? (
    //                 <Link
    //                   href={href}
    //                   target="_blank"
    //                   rel="noreferrer noopener"
    //                   color={isHighlighted ? baseColors.warning : darkColors.text}
    //                   bold={false}
    //                 >
    //                   {label}
    //                 </Link>
    //               ) : (
    //                 <StyledText>{label}</StyledText>
    //               )}
    //             </StyledListItem>
    //           ))}
    //         </StyledList>
    //       ))}
    //       <Box display={['none', null, 'block']}>{/* <LogoWithTextIcon isDark width="160px" /> */}</Box>
    //     </Flex>
    //     <StyledSocialLinks order={[2]} pb={['42px', null, '32px']} mb={['0', null, '32px']} />
    //     <StyledToolsContainer
    //       order={[1, null, 3]}
    //       flexDirection={['column', null, 'row']}
    //       justifyContent="space-between"
    //     >
    //       <Flex order={[2, null, 1]} alignItems="center">
    //         {/* <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} /> */}
    //         {/* <LangSelector
    //           currentLang={currentLang}
    //           langs={langs}
    //           setLang={setLang}
    //           color={darkColors.textSubtle as keyof Colors}
    //           dropdownPosition="top-right"
    //         /> */}
    //       </Flex>
    //       <Flex order={[1, null, 2]} mb={['24px', null, '0']} justifyContent="space-between" alignItems="center">
    //         <Box mr="20px">
    //           <CakePrice cakePriceUsd={cakePriceUsd} color={darkColors.textSubtle as keyof Colors} />
    //         </Box>
    //         <Button
    //           as="a"
    //           href="https://exchange.wagyuswap.app/swap?outputCurrency=0xaBf26902Fd7B624e0db40D31171eA9ddDf078351"
    //           target="_blank"
    //           scale="sm"
    //           endIcon={<ArrowForwardIcon color={lightColors.backgroundAlt} />}
    //         >
    //           {buyCakeLabel}
    //         </Button>
    //       </Flex>
    //     </StyledToolsContainer>
    //   </Flex>
    // </StyledFooter>
  )
}

export default MenuItem

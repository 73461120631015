import { useWalletModal } from 'packages/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
import { useWeb3React } from '@web3-react/core'
import Trans from 'components/Trans'

import { AbstradexStyledButton } from './styles'

const AbstradexConnectButton = ({ children, ...props }) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { account } = useWeb3React()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)
  const accountEllipsis = account ? `${account.substring(0, 5)}...${account.substring(account.length - 5)}` : null

  if (account) {
    return <AbstradexStyledButton>{accountEllipsis}</AbstradexStyledButton>
  }

  return (
    <AbstradexStyledButton onClick={onPresentConnectModal} {...props}>
      {children || <Trans>Connect Wallet</Trans>}
    </AbstradexStyledButton>
  )
}

export default AbstradexConnectButton

// export function wait(ms: number) {
//   return new Promise<void>((resolve) => {
//     setTimeout(() => {
//       resolve()
//     }, ms)
//   })
// }

import styled from 'styled-components'
import { Button, ButtonMenuItem } from 'packages/uikit'

const AbstradexStyledButton = styled(Button)`
  min-height: 44px;
  white-space: nowrap;

  border: none;
  border-radius: 25px;
  padding: 0 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;

  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.absMainColor};
  color: ${({ theme }) => theme.colors.absWhiteColor};

  > img {
    width: 24px;
    height: 24px;
  }

  .dropdown-icon {
    width: 16px;
    height: 16px;
  }
`

const AbstradexStyledActionButton = styled(Button)`
  position: relative;
  z-index: 1;
  white-space: nowrap;

  color: ${({ theme }) => theme.colors.absWhiteColor};
  background-color: ${({ theme }) => theme.colors.absMainColor};
  font-weight: 800;

  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 44px;
  border-radius: 10px;
  border: none;
`

const AbstradexModalClearAllButton = styled(Button)`
  font-size: 14px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.absBlackColor};
  opacity: 0.8;
  background: transparent;
  border: 0;
`

const TabButtonMenuItem = styled(ButtonMenuItem)`
  border-radius: 8px;
  background-color: ${({ theme, isActive }) =>
    isActive ? `${theme.colors.absMainColor}` : `${theme.colors.absBlackColor}`};
  color: ${({ theme, isActive }) => (isActive ? `${theme.colors.absWhiteColor}` : `${theme.colors.absWhiteColor}`)};
  font-weight: 700 !important;

  :hover {
    color: ${({ theme }) => theme.colors.absWhiteColor};
  }
`

export { AbstradexStyledButton, AbstradexStyledActionButton, AbstradexModalClearAllButton, TabButtonMenuItem }

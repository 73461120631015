import React, { useState } from 'react'
import styled from 'styled-components'
// import getExternalLinkProps from '../../util/getExternalLinkProps'
// import Grid from '../../components/Box/Grid'
import Box from '../../components/Box/Box'
import Text from '../../components/Text/Text'
import Heading from '../../components/Heading/Heading'
// import { Button } from '../../components/Button'
import { ModalBody, ModalCloseButton, ModalContainer, ModalHeader, ModalTitle } from '../Modal'
import WalletCard, { MoreWalletCard } from './WalletCard'
import config, { walletLocalStorageKey } from './config'
import { Config, Login } from './types'
import { Flex } from '../../components/Box'

interface Props {
  login: Login
  onDismiss?: () => void
  displayCount?: number
  t: (key: string) => string
}

const WalletWrapper = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  }
`

const Line = styled.div`
  background: #bfbfbf;

  width: 100%;
  height: 1px;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 2px;
    height: auto;
  }
`

const StyledModalContainer = styled(ModalContainer)`
  background: ${({ theme }) => theme.colors.absModalBg};
  height: auto;
  border-radius: 16px;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 701px;
  width: 100%;
`

const AbstradexWalletCard = styled(WalletCard)`
  padding: 0 0 16px 0;
`

const WrapperLeft = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: 24px;
  padding-right: 0px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-right: 36px;
  }
`

const WrapperRight = styled(Flex)`
  width: 100%;
  height: 100%;
  margin: auto;
  /* border-left: 1px solid rgba(191, 191, 191, 0.3); */
  /* padding-left: 36px; */

  flex-direction: column;
  justify-content: center;
`

const GridWalletWrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
`

const getPriority = (priority: Config['priority']) => (typeof priority === 'function' ? priority() : priority)

/**
 * Checks local storage if we have saved the last wallet the user connected with
 * If we find something we put it at the top of the list
 *
 * @returns sorted config
 */
const getPreferredConfig = (walletConfig: Config[]) => {
  const sortedConfig = walletConfig.sort((a: Config, b: Config) => getPriority(a.priority) - getPriority(b.priority))

  const preferredWalletName = localStorage?.getItem(walletLocalStorageKey)

  if (!preferredWalletName) {
    return sortedConfig
  }

  const preferredWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === preferredWalletName)

  if (!preferredWallet) {
    return sortedConfig
  }

  return [
    preferredWallet,
    ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== preferredWalletName),
  ]
}

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null, displayCount = 5, t }) => {
  const [showMore, setShowMore] = useState(false)
  const sortedConfig = getPreferredConfig(config)
  // Filter out WalletConnect if user is inside TrustWallet built-in browser
  const walletsToShow = window.ethereum?.isTrust
    ? sortedConfig.filter((wallet) => wallet.title !== 'WalletConnect')
    : sortedConfig
  const displayListConfig = showMore ? walletsToShow : walletsToShow.slice(0, displayCount)

  return (
    <StyledModalContainer>
      <ModalHeader background="#281736">
        <ModalTitle>
          <Heading color="absBlackColor">{t('Connect Wallet')}</Heading>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <ModalBody width={['100%', null, 'auto']}>
        <WalletWrapper>
          <WrapperLeft>
            <Text color="absBlackColor">
              {t(
                'Start by connecting with one of the wallets below. Be sure to store your private keys or seed phrase securely. Never share them with anyone.',
              )}
            </Text>
            <GridWalletWrapper>
              {displayListConfig.map((wallet) => (
                <Box key={wallet.title} maxWidth="100px">
                  <AbstradexWalletCard
                    walletConfig={wallet}
                    login={login}
                    onDismiss={onDismiss}
                    fontSize="14px"
                    fontColor="#000"
                  />
                </Box>
              ))}
              {!showMore && (
                <Box>
                  <MoreWalletCard t={t} onClick={() => setShowMore(true)} />
                </Box>
              )}
            </GridWalletWrapper>
          </WrapperLeft>

          <Line />

          <WrapperRight>
            <Flex justifyContent="center" flexDirection="column" alignItems="center">
              <img
                src="/images/abstradex/svgs/multi-wallet.svg"
                alt="multi-wallet"
                style={{ maxWidth: 'fit-content' }}
              />

              <Text color="absBlackColor">{t('Please confirm in your wallet')}</Text>
            </Flex>
          </WrapperRight>
        </WalletWrapper>
        {/* <WalletWrapper> */}

        {/* 
          <WrapperRight width="50%"></WrapperRight> */}
        {/* {!showMore && <MoreWalletCard t={t} onClick={() => setShowMore(true)} />} */}
        {/* </WalletWrapper> */}

        {/* <Line />

        <Box p="24px">
          <Text textAlign="center" color="absBlackColor" as="p" mb="16px">
            {t('Please confirm in your wallet')}
          </Text>
          <Button
            as="a"
            href="https://docs.wagyuswap.app/get-started/connection-guide"
            variant="subtle"
            width="100%"
            {...getExternalLinkProps()}
          >
            {t('Learn How to Connect')}
          </Button>
        </Box> */}
      </ModalBody>
    </StyledModalContainer>
  )
}

export default ConnectModal

import { Token } from '@abstra-dex/sdk'
import bridgeTokens from 'config/constants/tokenLists/bridge-mainnet.json'
import { useMemo } from 'react'

export interface BridgeProxyState {
  readonly typedValue: string
  readonly currencyId: string | undefined
}

export const getTokenBridge = () => {
  return bridgeTokens.tokens.map(
    (token) => new Token(token.chainId, token.address, token.decimals, token.symbol, token.name),
  )
}
export const getTokenForeign = (currency: Token) => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const token = bridgeTokens.tokens.find((token) => token.address.toLowerCase() === currency.address.toLowerCase())
  if (token) {
    return new Token(
      token.foreign.chainId,
      token.foreign.address,
      token.foreign.decimals,
      token.foreign.symbol,
      token.foreign.name,
    )
  }

  return undefined
}

export const useALlTokenBridges = () => {
  return useMemo(() => getTokenBridge(), [])
}
